.home-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

p {
  font-size: 1rem;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.lobby-div {
  border: 1pt solid black;
  padding: 1rem;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.join-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.text-input-field {
  max-width: 100pt;
}

.join-lobby-button {
  
}
