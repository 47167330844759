.enter-guess {
    margin-left: 8pt;
    margin-right: 8pt;
    margin-bottom: 8pt;
}

.btn-holder {
    display: flex;
    justify-content: flex-end;
}

.submit-btn {
    margin-top: 8pt;
}

.guess-list {
    margin-top: 8pt;
}