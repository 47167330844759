.board-container {
  display: flex;
  justify-content: center;
}

.game-over-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.overflow-text {
  text-align: center;
  margin-top: 10pt;
  margin-bottom: 10pt;
}